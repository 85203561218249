import Vue from 'vue'
//引入需要的组件
import { Button ,Icon, Tab, Tabs ,Cell, CellGroup,Field ,Form ,Dialog,Notify,Toast,Popup ,Picker,Checkbox, CheckboxGroup ,Overlay,Loading   } from "vant";
//一定要注册组件
Vue.use(Button);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Overlay);
Vue.use(Loading);