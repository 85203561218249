import axios from 'axios'
import Vue from 'vue'
import baseURL  from './baseUrl'
import { Notify } from "vant";


console.log('process.env',process.env);
//1.创建axios 实例
 const instance = axios.create({
  //  //1.1.设置请求头路径
    baseURL,
    //1.2设置请求过期时间
    timeout:300000,
 })

 //2.设置请求拦截器
 instance.interceptors.request.use(config =>{
  Vue.prototype.$fun.loding()
      
  let urlText = localStorage.getItem('URL')
  //判断是否是登录接口
    // 需要携带token 每一个请求请求头需要携带token
    // config.headers.Authorization=`Bearer ${token}`;
    console.log('config',config);

  return config
 },
 error=>{
   // 对请求错误做些什么
  //  alert('请求错误')
   return Promise.reject(error)
 })


 //3.响应拦截
 instance.interceptors.response.use( res=>{
  Vue.prototype.$toast.clear()
            console.log('请求接口返回的数据',res);
            console.log('路由',res.config.url== '/api/dingding/order/detail');
            let stu = res.data
            if(res.data.code === 500){
              Vue.prototype.$dialog.alert({
                message:res.data.message,
              });
            }
            console.log('执行相应拦截器',stu);
            
        return stu //return后的值被组件中的请求的then方法的res接收
},err =>{
  console.log('err.response',err);
if(err.response !== undefined  ){
 const status = err.response.status
   if (status == 401) {
    if(!localStorage.getItem('isLoginOut'))//登出标记
    {
      //存入标识已经触发过 权限弹窗了 后面就不会触发  所以后面代码不能删除 名为isLoginOut
      localStorage.setItem('isLoginOut', true);//登出标记
   
   }
   // 清空缓存
  localStorage.removeItem('token')
  localStorage.removeItem('username')
  localStorage.removeItem('user_id')
  sessionStorage.clear()
  }
 }

return Promise.reject(err)
})


 export default instance
