import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Notify } from "vant";
import index_fun from '@/utils/index'
// 引入组件文件
import '@/vant/vant'
//挂载axios
import axios from '@/api/axios';

Vue.prototype.$axios = axios  
Vue.prototype.$fun = index_fun
Vue.config.productionTip = false
Vue.prototype.$Notify = Notify

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
