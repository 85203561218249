import Vue from 'vue'

function loding() {
  Vue.prototype.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration:0,
    });
}


export default {
  loding
}