import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '@/api/axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'index',
    component: (resolve) => {
      return require(['@/views/index.vue'], resolve)
    },
  },
  {
    path: '/',
    redirect: '/index'
  },
  //二维码扫码 进入的是这个页面 ,打开微信 授权, 后端回调进入index
  {
    path: '/invioce',
    name: 'invioce',
    component: (resolve) => {
      return require(['@/views/invoice.vue'], resolve)
    },
  },
  {
    path: '/add_invoice',
    name: 'add_invoice',
    component: (resolve) => {
      return require(['@/views/add_invoice.vue'], resolve)
    },
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to,from,next)=>{
  if(window.location.href.indexOf('?') === -1 || to.path === '/invioce'){
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2761c028e926e43f&redirect_uri=http://report.camelcn.com/invioce/wetchat/callback&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
  }
  if(to.path === '/index'){
    sessionStorage.removeItem('indexList')
  }
  next()
})


export default router
