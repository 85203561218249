let baseURL = ""


switch (process.env.NODE_ENV){
  case 'production' :
    baseURL = process.env.VUE_APP_BASE_URL
    break;
  case 'development' :
    baseURL = process.env.VUE_APP_BASE_URL
    break;
}

export default baseURL;